let header = document.querySelector("header");
let headerHeight = header.clientHeight;
document.documentElement.style.setProperty(
    "--header-height",
    headerHeight + "px"
);
let footer = document.querySelector("footer");
let footerHeight = footer.clientHeight;
document.documentElement.style.setProperty(
    "--footer-height",
    footerHeight + "px"
);
document.onscroll = () => {
    if (window.scrollY > headerHeight / 2) {
        header.classList.add("backdrop-blur-sm");
    } else {
        header.classList.remove("backdrop-blur-sm");
    }
};