//refactor this script
let footerRightOuter = document.querySelector(".footer-right-outer");
let footerRightBall = document.querySelector("#footer-right-ball");
let footerRightInner = document.querySelector(".footer-right-inner");
let footerRightInnerHeight = footerRightInner.clientHeight;
let footerRightInnerWidth = footerRightInner.clientWidth;
let alphanumeric = [
    "@",
    "#",
    "$",
    "%",
    "&",
    "*",
    "+",
    "-",
    "/",
    "=",
    "?",
    "^",
    "_",
    "{",
    "|",
    "}",
    "~",
];
let totalDivs = Math.floor(
    ((footerRightInnerWidth / 45) * footerRightInnerHeight) / 45 + 10
);
for (let index = 0; index < totalDivs; index++) {
    let div = document.createElement("div");
    div.classList.add(
        "h-8",
        "w-8",
        "bg-white",
        "rotate-2",
        "rounded-md",
        "flex",
        "items-center",
        "justify-center",
        "uppercase",
        "text-black",
        "hover:bg-black",
        "hover:text-white",
        "transition-transform",
        "duration-500",
        "randoms",
        "cursor-pointer",
        "dark:bg-black",
        "dark:text-white",
        "dark:hover:bg-white",
        "dark:hover:text-black",
        "relative"
    );
    div.style.top = "0";
    div.style.left = "0";
    div.style.transition = `1s`;
    div.style.transitionTimingFunction = `cubic-bezier(0.25, 0.46, 0.45, 0.94)`;
    div.dataset.count = index;
    div.id = `random-${index}`;
    // div.style.marginRight = `${Math.cos(index) * 10}px`;
    div.innerText =
        alphanumeric[Math.floor(Math.random() * alphanumeric.length)];
    footerRightInner.appendChild(div);
}
let smallDivs = document.querySelectorAll(".randoms");
const parentRect = footerRightInner.getBoundingClientRect();
const parentCenterX = parentRect.left + parentRect.width / 2;
const parentCenterY = parentRect.top + parentRect.height / 2;
let closestDiv = null;
let closestDistance = Infinity;
let randomDivs = document.querySelectorAll(".footer-right-inner div");
randomDivs.forEach((div) => {
    div.addEventListener("mouseover", () => {
        div.style.transform = `rotate(${Math.random() * 360}deg)`;
    });
});
let targetelem = null,
    isDragging = false,
    offsetX = 0,
    offsetY = 0;

footerRightOuter.addEventListener("mousedown", (e) => {
    isDragging = true;
    targetelem = e.target.id || null;
    if (targetelem) {
        const target = document.getElementById(targetelem);
        const rect = footerRightOuter.getBoundingClientRect();
        offsetX = e.clientX - rect.left - parseFloat(target.style.left);
        offsetY = e.clientY - rect.top - parseFloat(target.style.top);
    }
});
footerRightOuter.addEventListener("mouseup", (e) => {
    if (targetelem) {
        const target = document.getElementById(targetelem);
        // target.style.top = "";
        // target.style.left = "";
    }
    isDragging = false;
});
footerRightOuter.addEventListener("mousemove", (e) => {
    let newX = e.clientX - footerRightOuter.getBoundingClientRect().left;
    let newY = e.clientY - footerRightOuter.getBoundingClientRect().top;

    // Ensure the footerRightBall element stays within the bounds of the footerRightOuter
    const maxX = footerRightOuter.clientWidth;
    const maxY = footerRightOuter.clientHeight;

    newX = Math.max(0, Math.min(newX, maxX));
    newY = Math.max(0, Math.min(newY, maxY));

    if (isDragging && targetelem) {
        document.getElementById(targetelem).style.left = `${newX - offsetX
            }px`;
        document.getElementById(targetelem).style.top = `${newY - offsetY}px`;
    }
    footerRightBall.style.left = `${newX}px`;
    footerRightBall.style.top = `${newY}px`;
});
// Iterate through each small div
smallDivs.forEach((div) => {
    const rect = div.getBoundingClientRect();
    const divCenterX = rect.left + rect.width / 2;
    const divCenterY = rect.top + rect.height / 2;

    // Calculate the distance from the center of the parent div to the center of the current small div
    const distance = Math.sqrt(
        Math.pow(divCenterX - parentCenterX, 2) +
        Math.pow(divCenterY - parentCenterY, 2)
    );

    // Check if this div is the closest to the center
    if (distance < closestDistance) {
        closestDistance = distance;
        closestDiv = div;
    }
});
let words = [
    "mujahid",
    "developer",
    "designer",
    "engineer",
    "coder",
    "programmer",
];
let wordcount = 0;
setInterval(() => {
    for (let index = 0; index < totalDivs; index++) {
        let randomDiv = document.getElementById(`random-${index}`);
        randomDiv.innerHTML =
            alphanumeric[Math.floor(Math.random() * alphanumeric.length)];
        randomDiv.style.backgroundColor = "";
        randomDiv.style.color = "";
        randomDiv.style.transform = `rotate(${Math.random() * 360}deg)`;
        // setTimeout(() => {
        //   randomDiv.style.transform = `rotate(360deg)`;
        // }, 300);
    }
    for (let index = 0; index < words[wordcount].length; index++) {
        let randomDiv = document.getElementById(
            `random-${Math.ceil(
                parseInt(closestDiv.dataset.count) -
                words[wordcount].length / 2 +
                index
            )}`
        );
        randomDiv.innerHTML = words[wordcount][index];
        randomDiv.style.transform = `rotate(0deg)`;
        randomDiv.style.top = "0";
        randomDiv.style.left = "0";
        randomDiv.style.zIndex = "10";
        if (document.querySelector("html").classList.contains("dark")) {
            randomDiv.style.backgroundColor = "white";
            randomDiv.style.color = "black";
        } else {
            randomDiv.style.backgroundColor = "black";
            randomDiv.style.color = "white";
        }
    }
    wordcount + 1 == words.length ? (wordcount = 0) : wordcount++;
}, 5000);