// let cursor = document.getElementById("cursor")
// document.body.addEventListener('mousemove', function (dets) {
//     cursor.style.left = dets.x + "px"
//     cursor.style.top = dets.y + "px"
//     document.body.style.cursor = 'none';
// });
// document.body.style.cursor = 'none';

// Refactored darkmode.js

// Get the darklightbtn element and html element
const darklightbtn = document.getElementById("darklightbtn");
const html = document.querySelector("html");
const metaThemeColor = document.querySelector('meta[name="theme-color"]');

let toggleDarkMode = () => {
    const prefersDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    prefersDarkMode ? html.classList.add("dark") : html.classList.remove("dark");
    metaThemeColor.setAttribute("content", prefersDarkMode ? "#1d1d1d" : "#ffffff");
}
window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", toggleDarkMode);
document.addEventListener("DOMContentLoaded", toggleDarkMode);
darklightbtn.addEventListener("click", () => {
    html.classList.toggle("dark");
    const theme = html.classList.contains("dark") ? "dark" : "light";
    // sessionStorage.setItem("theme", theme);
    metaThemeColor.setAttribute("content", theme === "dark" ? "#1d1d1d" : "#ffffff");
}
);
// Function to toggle between dark and light mode
// function toggleDarkMode() {
//     const prefersDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
//     html.classList.toggle("dark", prefersDarkMode);
//     metaThemeColor.setAttribute("content", prefersDarkMode ? "#1d1d1d" : "#ffffff");
// }

// // Check if theme is stored in sessionStorage, if not, apply dark mode
// if (!sessionStorage.getItem("theme")) {
//     toggleDarkMode();
// } else {
//     html.classList.add(sessionStorage.getItem("theme"));
// }


// // Function to toggle between dark and light mode on button click
// function toggleTheme() {
//     html.classList.toggle("dark");
//     const theme = html.classList.contains("dark") ? "dark" : "light";
//     sessionStorage.setItem("theme", theme);
//     metaThemeColor.setAttribute("content", theme === "dark" ? "#1d1d1d" : "#ffffff");
// }
// darklightbtn.addEventListener("click", toggleTheme);
