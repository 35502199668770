import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);



const skillsOuterElements = document.querySelectorAll(".skills-outer");
const totalElements = skillsOuterElements.length;
gsap.set(".skills-outer", {
    rotate: (index) => index * 360 / totalElements,
});
gsap.set(".skills-inner svg", {
    rotate: (index) => index * -360 / totalElements,
});
gsap.set(".skills", {
    opacity: 0,
});
let heroSectionTimeline = gsap.timeline({
    scrollTrigger: {
        trigger: "#hero-section",
        start: "top top",
        end: "bottom top",
        scrub: true,
        pin: true,
    },
});

heroSectionTimeline.to("#myimage", {
    opacity: 0,
    y: -100,
    duration: 2,
}).to("#mobile-main-heading , #large-main-heading", {
    opacity: 0,
    y: -100,
    duration: 2,
}).to("#hero-image-container", {
    x: "-0.75rem",
    y: "-0.75rem",
}, "-=0.5")

let aboutSectionTimeline = gsap.timeline({
    scrollTrigger: {
        trigger: "#about-section",
        start: "-70% 60%",
        end: "bottom 60%",
        scrub: 1,
        pin: true,
    },
});

aboutSectionTimeline
    .to("#about-section", {
        yPercent: -100,
    }, "same")
    .from("#about-paragragh", {
        opacity: 0,
    })
    .from(".underlineanimatedabout", {
        width: 0,
        duration: 1,
    }).to("#about-paragragh", {
        duration: 2,
        opacity: 0,
    })
let whoSectionTimeline = gsap.timeline({
    scrollTrigger: {
        trigger: "#who-section",
        start: "-400% 70%",
        end: "200% 70%",
        scrub: 1,
        pin: true,
    },
});

whoSectionTimeline
    .to("#who-section", {
        yPercent: -535,
        duration: 5,
    })
    .to(".questions", {
        x: (index) => (index % 2 === 0 ? -100 : 100),
        duration: 2,
        stagger: 0.5,
        opacity: 0,
        display: "none",
    }).from(".skills", {
        width: 0,
        height: 0,
        duration: 2,
        opacity: 0,
    }, "same")
    .to("#who-heading", {
        opacity: 0,
        duration: 2,
        scale: 0.5,
    }, "same")
    .to(".skills", {
        borderRadius: "50%",
        height: "3rem",
        width: "3rem",
        duration: 2,
        rotate: 0,
    }, "same")
    .to(".skills", {
        x: "8rem",
        stagger: 0.5,
        opacity: 1,
    }, "same1").from(".skills-container", {
        rotate: 360,
        duration: totalElements,
        snap: {
            rotate: (value) => Math.round(value / totalElements) * totalElements,
        },
    }, "same1").from(".skills", {
        rotate: -360,
        duration: totalElements,
    }, "same1")
    .to("#who-heading", {
        opacity: 1,
        duration: 2,
        innerText: "*I am surrounded by these skills",
    }, "same1")
    // .to(".skills", {
    //     x: 0,
    //     opacity: 0,
    //     duration: 4,
    //     stagger: 0.5,
    // })
    // .to("#who-section", {
    //     opacity: 0,
    //     duration: 2,
    // }, "same2") 








// Ensure GSAP and the ScrollToPlugin are loaded
gsap.registerPlugin(ScrollToPlugin);

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault(); // Prevent default anchor behavior

        const targetId = this.getAttribute('href'); // Get the ID from the href attribute
        const targetElement = document.querySelector(targetId); // Find the target element
        const currentScroll = window.scrollY;
        const isScrollingUp = currentScroll > targetElement.offsetTop;
        if (targetElement) {
            // Use GSAP to scroll to the target element
            gsap.to(window, {
                duration: 1,
                scrollTo: {
                    y: targetElement,
                    offsetY: (isScrollingUp ? targetElement.offsetHeight : 0), // Adjust the offset to go to the start of the pinned element when moving up

                    // Adjust the offset to go to the start of the pinned element when moving up
                },
            });
        }
    });
});


let skillsOuter = document.querySelectorAll(".skills-outer");
      let lastmouseover = -1;
        let totalSkills = skillsOuter.length;
        skillsOuter.forEach((skill, index) => {
            skill.addEventListener("mouseover", () => {
              if (lastmouseover == index) {
                return;
              }
              let name = skill.dataset.name;
              document.querySelector("#who-heading").textContent = name;
                lastmouseover = index;
                
            })
            skill.addEventListener("mouseout", () => {
              document.querySelector("#who-heading").textContent = "*I am surrounded by these skills";
              lastmouseover = -1;
            })
        });




ScrollTrigger.refresh();